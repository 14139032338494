.container{
    background-color: #0e2245;
    padding: 15px;
    text-align: center;
    color: white;
}
.right{
    background-color: #f5c52b;
    padding: 5px 30px;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    text-decoration: none;
    color: white;
}