.new-footer-container{
    height: 400px;
    background-color: #302c2b;
    padding-top: 60px;
}
.new-footer-innter-container{
    width: 86%;
    /* border: 1px solid white; */
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.new-footer-card{
    width: 300px;
    /* border: 1px solid white; */
}
.new-footer-card-heading{
    font-family: "Segoe UI";
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 40px;
}
.new-footer-content{
    font-family: "Segoe UI";
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 16;
    line-height: 26px; 
}
.new-footer-links{
    font-family: "Segoe UI";
    color: rgb(255, 255, 255);
    text-decoration: none;
}
.new-footer-links:hover{
    color: rgb(255, 77, 0);
}
@media only screen and (max-width: 600px){
    .new-footer-innter-container{
        flex-direction: column;        
    }
    .new-footer-container{
        height: fit-content;
        padding-bottom: 50px;
    }
}