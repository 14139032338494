.aboutText {
  background-color: antiquewhite;
  padding: 15px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.ChemistryLabImage {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.houssesContainer {
}

.houseTextSection {
  padding: 15px;
}

.houseCard {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  width: 100%;
  border-radius: 30px;
  margin-bottom: 30px;
  /* padding: 10px; */
}

.houseTeacher {
  display: inline-block;
  margin-right: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.nameHouse {
  display: inline;
  margin-right: 10px;
}
.captain {
  display: inline;
  margin-right: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.viceCaptain {
  display: inline;
  margin-right: 0.625rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.houseEvents {
  display: inline;
  margin-right: 0.625rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: auto;
  border-radius: 1.875rem 1.875rem  0 0;
  -webkit-border-radius: 1.875rem 1.875rem  0 0;
  -moz-border-radius: 1.875rem 1.875rem  0 0;
  -ms-border-radius: 1.875rem 1.875rem  0 0;
  -o-border-radius: 1.875rem 1.875rem  0 0;
}