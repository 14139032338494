.admission_form_container {
  display: flex;
  justify-content: center;
}

.admissionForm {
  padding: 10px;
  align-items: center;
  width: 90%;
}

@media (min-width: 576px) {
  .admissionForm {
    width: 70%;
  }
}

.form_fields {
  display: flex;
  justify-content: space-between;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  color: #0e2245;
  font-weight: bold;
  /* background: red; */
}

.form_fields input {
  width: 100%;
}

.form_fields textarea {
  width: 100%;
}

@media (min-width: 1024px) {
  .form_fields input {
    width: 80%;
  }

  .form_fields textarea {
    width: 80%;
  }
}

.submit_button {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

#student_name {
  padding: 10px 20px;
  -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b;
  border-radius: 30px;
  /* border: 1px solid #f5c52b; */
  border: none;
  background-color: transparent;
}
#student_name:focus {
  outline: none;
}

#student_age {
  padding: 10px 20px;
  -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b;
  border-radius: 30px;
  /* border: 1px solid #f5c52b; */
  border: none;
  background-color: transparent;
}
#student_age:focus {
  outline: none;
}
#student_previous_school {
  padding: 10px 20px;
  -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b;
  border-radius: 30px;
  /* border: 1px solid #f5c52b; */
  border: none;
  background-color: transparent;
}
#student_previous_school:focus {
  outline: none;
}
#student_contact_number {
  padding: 10px 20px;
  -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b;
  border-radius: 30px;
  /* border: 1px solid #f5c52b; */
  border: none;
  background-color: transparent;
}
#student_contact_number:focus {
  outline: none;
}
#student_address {
  padding: 10px 20px;
  -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b;
  border-radius: 30px;
  /* border: 1px solid #f5c52b; */
  border: none;
  background-color: transparent;
}
#student_address:focus {
  outline: none;
}

#submit_btn {
  padding: 0.5rem 5rem;
  border-radius: 10px;
  /* -moz-box-shadow: 0 0 10px #f5c52b;
  -webkit-box-shadow: 0 0 10px #f5c52b;
  box-shadow: 0 0 10px #f5c52b; */
  background-color: #f5c52b;
  font-size: 20px;
  color: white;
  font-weight: bold;
  border: 0.2rem solid #f5c52b;
}

#submit_btn:hover {
  background-color: transparent;
  color: #f5c52b;
}

.admission_enquary_form {
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.w-full {
  width: 100%;
}

.error_message {
  font-size: 0.9rem;
  color: red;
  text-align: center;
}

.error_message p {
  margin-bottom: 0.4rem;
}

.required {
  color: #f5c52b;
}

.admission_success_message {
  text-align: center;
  font: 1rem;
  color: #f5c52b;
  font-weight: bold;
}
