.new_hero_image_container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
        url('https://www.chatterjeenobleschool.com/static/media/school_web.69a2c2a165ecb222b5cb.jpg') no-repeat center center;
    background-size: cover;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.new-hero-image-left-container {
    width: 60%;
}


.hero-image-left-heading {
    margin-top: 50px;
    color: white;
    font-family: "Raleway";
    font-size: 50px;
    font-weight: 900;
    line-height: 60px;
    text-wrap: wrap;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.hero-image-additional{
    color: white;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-wrap: wrap;
    font-family: 'Roboto';
    font-size: 18px;
    /* font-weight: bold; */
}
.hero-image-btn-link{
    background-color: brown;
    padding: 10px 30px;
    text-decoration: none;
    color:white;
    /* margin-left: 10px; */
    font-size: 1.4rem;
    
}
@media only screen and (max-width: 500px) {
    .new_hero_image_container {
        flex-direction: column;
        border: 1px solid rgb(241, 238, 238);
        width: 100%;
    }

    .hero-image-left-heading {
        margin-top: 50px;
        color: white;
        font-family: "Raleway";
        font-size: 36px;
        font-weight: 900;
        line-height: 40px;
        text-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

    }

    .new-hero-image-left-container {
        width: 100%;
        margin-top: 30px;
    }
    .hero-image-additional{
        margin-top: 30px;
        color: white;
        font-family: "Raleway";
        font-size: 16px;
        font-weight: 400;
        /* line-height: 40px; */
        text-wrap: wrap;
        width: 90%;
        margin-left: auto;
        margin-right: auto; 
    }
    .hero-br{
        display: none;
    }
    .hero-image-btn-link{
        display: block;
        text-align: center;
        margin-left: 0;
        margin-top: 10px;
        width: fit-content;
    }
}
