.news_card_left_box {
  background-color: #f5c52b;
  padding: 15px;
  border-top-left-radius: 20px;
  text-align: center;
}
.day {
  color: #0e2245;
  font-weight: bold;
}
.month {
  color: #0e2245;
  font-weight: bold;
}
.news_heading {
  font-size: 20px;
  font-weight: bold;
}

.notice-separator {
  border: none;
  border-bottom: 1px solid gray;
}

.notice_link {
  text-decoration: none;
  color: inherit;
  display: block;
  border-radius: 1.4rem 0 0 0;
}
.notice_link:last-of-type {
  border-bottom: none;
}

.notice_link:hover {
  color: inherit;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
