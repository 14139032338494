.why-choose-cns-container{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.why-choose-cns-left-container{
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: #1e1c29;
    color: #ffff;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;

}
.why-choose-cns-heading{
    padding-top: 60px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.why-choose-cns-right-container{
    width: 40%;

}
#why-cohhose-cns-image{
    width: 100%;
    height: 100%;
}
.why-choose-cns-text{
    width: 70%;
    /* text-align: justify; */
    margin-left: auto;
    margin-right: auto;
    font-family: 'Raleway';
    font-size: 14px;
    line-height: 23px;
    color: #ffff;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
}
.why-choose-cns-bottom{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.why-choose-cns-card{
   background-color: #ffff;
   padding-left: 15px;
   padding-right: 15px;
   width: 48%;
   border-radius: 10px;
   padding-bottom: 10px;
    margin-bottom: 30px;
}
.why-choose-cns-card-heading{
    color: #730e1c;
    font-family: "Segoe UI";
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}
.why-choose-cns-card-text{
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 26px;
    color: rgb(58, 79, 102);
    font-weight: 400;
    text-align: justify;
}


@media only screen and (max-width: 600px){
    .why-choose-cns-container{
        flex-direction: column;
    }
    .why-choose-cns-left-container{
        width: 100%;
    }
    .why-choose-cns-bottom{
        width: 100%;
        flex-direction: column;
    }
    .why-choose-cns-card{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    #why-cohhose-cns-image{
        display: none;
    }
}