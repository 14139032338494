.hero_image_container{
border: 1px slid red;
}
.hero_image{
    display: block;
    width: 100%;
    object-fit: cover;
    height: 350px;
}
.hero_image_bottom_tag_line{
    padding: 5px;
    background-color: #ecf5ff;
}
.moving_text{
    animation-name: text_move;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    color: #0e2245;
    font-weight: bold;
}
@keyframes text_move{
    from{transform: translateX(00px);}
    to{transform: translateX(100%);}
}