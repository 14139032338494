.exam-btn{
    padding: 10px;
    text-align: center;
    width: 80%;
    background-color: brown;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: block;

}