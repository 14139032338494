

.nav-top-left {
  background-color: #f5c52b;
  padding: 10px;
}
.nav-top-right {
  background-color: #0e2245;
  padding: 10px;
}
.nav-top-text {
  color: white;
}
.nav-top-right-container {
  /* padding-left: 100px; */
  /* padding-right: 100px; */
}

.nav-top-right-btn {
  background-color: #f5c52b;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  border: 0.1rem solid #f5c52b;
}

.nav-top-right-btn:hover {
  background-color: transparent;
}

.navbar-cns-logo {
  width: 5rem;
  height: 4rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.school-name {
  font-size: 1.35rem;
  font-weight: bold;
  color: #f5c52b;
  margin-top: 10px;
  text-align: center;
}

@media (min-width: 576px) {
  .navbar-cns-logo {
    width: 5rem;
    height: 5rem;
    margin-top: 0.5rem;
  }

  .school-name {
    font-size: 25px;
  }
}

.school-affiliation {
  text-align: center;
  font-weight: bold;
  color: #0e2245;
}
.navbar-items {
  text-align: center;
  /* border-left: 1px solid black; */
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}

.navbar-items input[type='checkbox'] {
  display: none;
}

.navbar-items input[type='checkbox'] ~ .dropdown_list,
.navbar-items input[type='checkbox'] ~ .dropdown_list_toggler {
  display: none;
}

.navbar-items input[type='checkbox']:checked ~ .dropdown_list,
.navbar-items input[type='checkbox']:checked ~ .dropdown_list_toggler {
  display: block;
}
/* this is for the total nav bar container */
.total-nav-caonatiner {
  z-index: 100;
  background-color: white;
}
.additional_section {
  background-color: #ecf5ff;
}
/* all the navigation link related code goes here */
.nav_link {
  text-decoration: none;
  color: black;
}
.dropdown_list {
  margin-top: 10px;
  min-width: 13rem;
  width: max-content;
  position: absolute;
  z-index: 10;
  background-color: #0e2245;
  padding: 1rem 0.5rem;
  padding-bottom: 0.5rem;
  left: 0;
  right: 0;
  transform: translateX(-20%);
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 4px solid #f5c52b;
  border-top: 4px solid #f5c52b;
}
.nav_menu_btn {
  padding: 0.3rem 0.4rem;
  cursor: pointer;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 0.94rem;
}
.nav_menu_btn:hover {
  background-color: #f5c52b;
  color: #0e2245;
  border-radius: 0.4rem;
}
.nav_menu_btn i {
  margin-left: 0.15rem;
  font-size: 0.7rem;
}

.nav_menu_btn:active {
}

/* navigation link code ends here */
.circle {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: red;
}

.top-nav-link-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  justify-content: center;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.top-nav-link-item a {
  color: white;
  text-decoration: none;
}

.top-nav-link-item a:hover {
  color: #f5c52b;
}

.mobile-menu-btn {
  outline: none;
  right: 1rem;
  bottom: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem !important;
  height: 1.8rem;
  color: white;
  padding: 0.4rem;
  border-radius: 0.3rem;
  background-color: #f5c52b;
  border: 0.1rem solid #f5c52b;
}

.mobile-menu-btn:hover {
  color: #f5c52b;
  background: white;
}

.mobile-menu-list {
  height: 50vh;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  top: 5.6rem;
  border-top: 0.2rem solid #f5c52b;
  padding: 1rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 0.3rem solid #f5c52b;
  border-radius: 0 0 4rem 0;
}

@media (min-width: 576px) {
  .mobile-menu-btn {
    bottom: 35%;
  }
  .mobile-menu-list {
    top: 6.7rem;
  }
}

.mobile-menu-list .mobile-list-item {
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.mobile-menu-list .mobile-list-item input[type='checkbox'] {
  display: none;
}
.mobile-menu-list .mobile-list-item i {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.mobile-menu-list .mobile-list-item .mobile-menu-link-list {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.mobile-menu-list .mobile-list-item .mobile-menu-link-list a {
  color: #0e2245;
  font-weight: 500;
}

.mobile-menu-list .mobile-list-item input[type='checkbox'] ~ .mobile-menu-link-list {
  display: none;
}

.mobile-menu-list .mobile-list-item input[type='checkbox']:checked ~ .mobile-menu-link-list {
  display: block;
}
