.Gallery_Image_container{
    /* -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc; */
  width: 270px;
  height: 270px;
  padding: 10px;
  margin-bottom: 30px;
  /* border: 1px solid #f5c52b; */
  border-radius: 10px;
}
.gallery_container{
   
   
}