.cbse-games-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.cbse-games-location{
    margin-top: 50px;
}
.cbse-games-form{
    width: 45%;
}
.cbse-games-location{
    width: 45%;
    padding-right: auto;
}
.cbse-games-form-inner{
    width: 100%;
    height: 1500px;
}
.cbse-games-map{
    width: 100%;
    height: 650px;
}
.anexture{
    display: block;
    background-color: brown;
    text-align: center;
    padding: 10px;
    color: white;
    text-decoration: none;
}
@media only screen and (max-width: 500px){
    .cbse-games-container{
        flex-direction: column;
    }
    .cbse-games-form{
        width: 100%;
    }
    .cbse-games-location{
        width: 100%;
    }
}