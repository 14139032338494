.karateImages{
    width: 300px;
    height: 300px;
   /* border-top-left-radius: 10px;
   border-bottom-right-radius: 10px; */


   border-radius: 10px;
}
.karateImageCard{
    margin-top: 30px;
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
    height: 320px;
    width: 320px;
    padding-left: 10px;
    padding-top: 10px;
}