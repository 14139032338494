
.latest-events-container{
    /* border: 1px solid black; */
    width: 70%;
    margin-right: auto;
    margin-left: auto;
}
.latest-events-card{
    width: 340px;
    margin-bottom: 30px;
}

.letest-events-image-container{
    
}
.letest-events-image{
   width: 400px;
}
.latest-events-heading{
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Segoe UI';
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    color: rgb(115, 15, 28);
    text-align: center;
}
.latest-events-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.latest-events-title{
    font-family: 'Segoe UI';
    color: rgb(115, 15, 28);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;  
    padding-left: 10px;
    padding-right: 10px;
    text-wrap: wrap;
    
}
.latest-events-description{
    color: rgb(58, 79, 102);
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}
@media only screen and (max-width: 600px){
    #latest-event-hide{
        display: hidden;
    }
    .latest-events-container{
        width: 100%;
    }
    .latest-events-card{
        margin-left: auto;
        margin-right: auto;
    }
    .latest-events-row{
        flex-direction: column;   
    }
}
