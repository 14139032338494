.infra_filter_container {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.innter_filter_container {
  display: flex;
  justify-content: space-evenly;
  width: max-content;
}
.filter_botton {
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  background-color: #f5c52b;
  margin-right: 10px;
  border-radius: 30px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.filter_botton:hover{
  background-color: #0e2245;
  color: #f5c52b;
}
.filter_botton_active{
  background-color: #0e2245;
  color: #fff;
  margin-left: 10px;
  padding: 10px 20px 10px 20px;
  margin-right: 10px;
  border-radius: 30px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}

