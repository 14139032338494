.clubContainer {
  background-color: #f6f9fe;
  padding: 20px;
}
.club {
  margin-left: 25%;
  margin-right: 25%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.clubHeading {
  text-align: center;
}
.clubHeading h1 {
  color: #f5c52b;
}
.clubDescription {
  text-align: center;
  color: #0e2245;
}
.club_card_container {
  margin-top: 50px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 50px;
}
.club_card {
  /* background-color: #13450e; */
  /* border: 1px solid black; */
  width: 300px;
  border-radius: 20px;
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}
.club_card_img img {
  width: 300px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.club_card_description {
  padding: 15px;
}
.club_name {
  font-weight: bold;
  text-align: center;
  font-size: 32px;
  margin-bottom: 10px;
  color: #f5c52b;
}
.club_president {
  font-weight: bold;
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .club {
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .club_card{
    margin-left: auto;
    margin-right: auto;
  }
}
