.vmcontainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 30px 20px 20px 20px;
  text-align: justify;
  border-radius: 30px;
  margin: 20px 0px 20px 0px;
}
.vmImage_container {
  margin: 10px 10px 10px -40px;
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  padding: 10px;
  border-radius: 35px;
  z-index: 1;
  background-color: white;
}
.vmdescription {
  padding: 10px;
  color: #919191;
}
.vmImage {
  height: 100px;
  width: 100px;
}
.vmTitle{
    display: block;
    font-size: 24px;
    margin-top: -10px;
    color: #0e2245;
}
@media only screen and (max-width: 600px)  {
    .vmcontainer{
        display: block;
    }
    .vmImage_container{
        width: fit-content;
    }
}