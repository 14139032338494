.home-page-container{
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}
.home-page-right-container{
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
}
.home-page-heading{
    font-family: "Raleway";
    font-weight: 700;
    font-size: 40px;
    line-height:48px;
    color: rgb(115, 15, 28);
}
.home-page-content{
    margin-top: 30px;
    font-family: 'Roboto';
    text-align: justify;
    color: rgb(58, 79, 102);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}
#home-page-image{
    width: 100%;
}
@media only screen and (max-width: 600px){
    .home-page-container{
        flex-direction: column;
        width: 100%;
    }
    .home-page-heading{
        font-size: 26px;
        line-height: 36px;
    }
}