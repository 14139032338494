.table_container {
  margin-top: 25px;
  margin-bottom: 30px;
}
.table_component_tr {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
}
.table_component_td {
  /* border: 1px solid green; */
  padding: 10px;
}
.table_component_th {
  border: 1px solid #f5c52b;
  padding: 10px;
  color: white;
  background-color: #0e2245;
  
}
.table_component_tr:nth-child(even) {
  /* background-color: #e8e8e8; */
}
.tableComponent {
  border-collapse: separate;
  border-spacing: 0 15px;
}
