.artgallery {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  width: 100%;
  position: relative;
  /* new */
  -moz-box-shadow: 0 0 0.625rem #ccc;
  -webkit-box-shadow: 0 0 0.625rem #ccc;
  box-shadow: 0 0 0.625rem #ccc;
  padding: 0.625rem;
  border-radius: 0.625rem;
}

.art_heading {
  padding: 0.625rem;
}

.art_text {
  color: #f5c52b;
  font-size: 1.4375rem;
}

.straight_line {
  height: 0.1rem;
  width: 100%;
  background-color: #f5c52b;
  margin-top: 1.25rem;
  z-index: -1;
  margin-right: 0.5rem;
}

.btn-container {
  z-index: 10;
  flex-shrink: 0;
}

.art_img {
  width: 100%;
  height: 31.25rem;
  display: block;
}

/* responsive code starts here */
@media only screen and (max-width: 600px) {
  .artgallery {
    height: 31.25rem;
    width: 100%;
  }
}
