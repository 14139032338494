.carousel_item_custom {
}
.moving_text {
  animation-name: text_move;
  color: whitesmoke;
  animation-duration: 25s;
  animation-iteration-count: infinite;
}
@keyframes text_move {
  from {
    transform: translateX(00px);
  }
  to {
    transform: translateX(100%);
  }
}

/* @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  } */
.flash_news {
  padding-left: 10px;
}
.flash_news_heading {
  background-color: #f5c52b;
  padding-top: 5px;
  padding-bottom: 5px;
  height: fit-content;
  font-weight: bold;
  padding-left: 30px;
}
.flash_news_news {
  color: white;
  background-color: #0e2245;
  padding-top: 5px;
  padding-bottom: 5px;
  height: fit-content;
}

.Home_about_school {
  padding: 30px;
}
.about_school_text_area {
  margin-top: 70px;
}
.about_school_text_area h1 {
  text-align: center;
  margin-bottom: 30px;
}
.first_about_school {
  color: #f5c52b;
}
.second_about_school {
  color: #0e2245;
}
#school_cartoon {
}

/* aditional section starts here */
.additional_section {
  padding-top: 30px;
}
.additional_section h1 {
  text-align: center;
  color: #0e2245;
}
.additional_section p {
  text-align: center;
  color: #0e2245;
}
.aditional_card {
  height: 350px;
  /* border: 1px solid black; */
  /* width: 300px; */
  margin-top: 40px;
}
.aditional_card_img {
  height: 100px;
  width: 100px;
  margin-bottom: 30px;
}
.aditional_section_card_container {
  text-align: center;
}

.flip-box {
  background-color: transparent;
  /* width: 300px; */
  height: 110px;
  /* border: 1px solid #f1f1f1; */
  /* border: 1px solid black; */

  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: scale(1.5) rotateY(180deg);
  /* transform: scale(1.5) */
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  /* background-color: #bbb;
  color: black; */
}

.flip-box-back {
  /* background-color: #555;
  color: white; */
  transform: rotateY(180deg);
}

/* children image and news section css starts here */
.news_section {
  padding-top: 50px;
  padding-bottom: 50px;
}
/* children image and news section css ends here */
.desk {
  background-color: #ecf5ff;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  /* background-image: url('../images/quotes.png') #d6d6d4;
  background-repeat: no-repeat;
  background-size: 400px; */
}
.principal_img {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;

  /* margin-bottom: auto; */

  border-radius: 50%;
  background-color: #f5c52b;
}
.principal_img_container {
  background-color: white;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  /* margin-top: 50px; */
}
.designation {
  text-align: center;
  font-size: 25px;
  margin-top: 10px;
  color: #0e2245;
}
.designation_school {
  text-align: center;
  color: #fac20a;
}
.designation_text {
  text-align: center;
  padding: 10px;
}

.news_section_container {
  display: flex;
  /* justify-content: space-around; */
  padding: 10%;
}
.headmistressDesk{
  background-color: #d9dee3;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

/* responsive code starts here */
@media only screen and (max-width: 600px) {
  .youtubeVideoHomePage {
    width: 100%;
  }
  .news_section_container {
    padding: 3px;
  }
  #school_cartoon {
    width: 100%;
  }
  #flash_news{
    display: none;
  }
}
