.social_media_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 50px 0px;
}
.social_link{
    text-decoration: none;
}
@media screen and (max-width: 600px){
  .social_media_container{
    display: block;
    padding: 50px 0px;
  } 
  .social_medial_site{
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
    background-color: antiquewhite;
  } 
}