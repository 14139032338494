.capsule{
    background-color: #dfdedc;
    display: inline-block;
    padding: 5px 20px 5px 20px;
    margin: 10px 10px 0px 0px;
    border-radius: 50px;
}
.pr-1{
    padding-right: 10px;
}
