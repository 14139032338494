.calender_container{
    margin-top: 30px;
    margin-bottom: 30px;
    
}



.calender_body{
height: 700px;
}
::-webkit-scrollbar{
    width:10px;
}
::-webkit-scrollbar-button{
    display: none;
}
::-webkit-scrollbar-track{
    /* background: blue; */
}
::-webkit-scrollbar-thumb{
    background: 
    #f5c52b;
}