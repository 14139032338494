.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    height: 90vh;
    width: 90vh;
    color: #f5c52b;
  }
}
