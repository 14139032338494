.title_outer_layer {
    background-color: #f5c52b;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 50px;
  }
  .title_section {
    background: rgb(8, 8, 109);
    background: linear-gradient(
      90deg,
      rgba(8, 8, 109, 1) 0%,
      rgba(9, 9, 120, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(9, 9, 121, 1) 0%,
      rgba(9, 9, 121, 1) 2%,
      rgba(9, 9, 121, 1) 2%,
      rgba(6, 75, 165, 1) 40%,
      rgba(255, 255, 255, 1) 100%
    );
    padding: 5px 0px 5px 30px;
    color: aliceblue;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 50px;
    /* border-bottom-right-radius: 50px; */
  }