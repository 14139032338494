.heading_section_container {
  margin-top: 30px;
}
.heading {
  font-size: 28px;
  color: #0e2245;
  margin-top: 10px;
  display: inline-block;
}
.line_container{
    display: inline;
}
.straight_line_heading{
    display: block;
    background-color: #f5c52b;
    width: 100%;
    height: 1px;
    margin-top: 34px;
    margin-left: 5px;
}
.sub_heading{
    color: #acacacef;
    
}
