.faq-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    
}
.faq-left-container{
    width:40%;
    overflow-y: hidden;
}
.faq-right-container{
    width: 60%;
    background-color: #730e1c;
    display: flex;
    flex-direction: column;
}
.faq-image{
    width: 100%;
    height: 100%;
}

.faq-item {
    margin-bottom: 0px;
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  .faqs-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
  }
  .faq-question{
    background-color: #620c19;
    color: #fff;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 26px;
    font-size: 16px;
    padding: 16px;
    margin-bottom: 16px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .faq-answer{
    color: #fff;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 16px;
    padding-left: 16px;
  }
  .faq-section-heading{
    color: #fff;
    font-family: 'Raleway';
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 600px){
    .faq-left-container{
      display: none;
    }
    .faq-right-container{
      width: 100%;
      padding-bottom: 50px;
    }
  }