.top_nav_bar_container{
    height: 55px;
    background-color: #0e2245;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.top_nav_bar_left_container{
    background-color: #f5c52b;
    height: 49px;
    width: 30%;
    margin-top: 0px;
    margin-bottom: auto;
}

#triangle-bottomright {
    margin-top: 0px;
    margin-bottom: auto;
    width: 0;
    height: 0;
    border-bottom: 50px solid #0e2245;
    border-left: 50px solid transparent;
    margin-left: -50px;
  }
  #top_nav_bar_left_text{
    font-family:  "Helvetica";
    margin-left: 30px;
    font-size: 14px;
    margin-top: 15px;
    font-weight: 400;
  }
  .top_nav_bar_right_container{
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  #top_nav_bar_right_text{
    color: #ffff;
    font-family:  "Helvetica";
    margin-top: 17px;
    font-weight: 400;
    font-size: 13px;
  }
.new_top_nav_mobile_number{
    color: #ffff;
    margin-top: 17px;
}

.new_top_nav_email_id{
    color: #ffff;
    margin-top: 17px;
}

/* the bottom main navigation  */
.new_navigation_bar{
  height: 70px;
  background-color: white;
}
@media only screen and (max-width: 500px){
  .top_nav_bar_container{
    display: none;
  }
}