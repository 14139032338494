

.new_admission_form_container {
    height: 500px;
    width: 500px;
    padding: 0px 35px;
    border-radius: 20px;
    /* background-color: rgba(19, 19, 19, 0.3); */
    background-color: rgba(230, 230, 230, 0.4);
    
    margin-left: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: 140px;
   
}
/* 
input::placeholder {
    
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: red;
} */

/* For better browser compatibility, add vendor prefixes */
/* input::-webkit-input-placeholder {
    font-family: "Open Sans";
    font-size: 14px;
    color: red;
}

input::-moz-placeholder {
    font-size: 14px;
    color: red;
}

input:-ms-input-placeholder {
    font-size: 14px;
    color: red;
}

input:-moz-placeholder {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: red;
} */
#admission_form_heading{
    font-family: "Raleway";
    font-weight: 800;
    text-align: center;
    padding: 20px 0px;
    font-size: 20px;
    color: #ffff;
}
.new_admission_form_input{
    font-family: "Open Sans";
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}
.new_admission_form_input::placeholder{
    font-family: "Raleway";
    font-weight: 600;
    font-size: 16px;

}
.new_admission_form_input:focus{
    border-color: none;
    outline: none;
}
#new_admission_form_id{
    width: 100%;
    background-color: #730e1c;
    border: none;
    padding: 10px;
    text-align: center;
    color: #ffff;
    font-family: "Raleway";
}

/* code for mobile devices */
@media only screen and (max-width: 500px){
    .new_admission_form_container{
        height: 500px;
        width: 90%;
        padding: 0px 15px;
        border-radius: 20px;
        /* background-color: rgba(19, 19, 19, 0.3); */
        background-color: rgba(230, 230, 230, 0.4);
        margin-left: auto;
        margin-bottom: 30px;
        margin-top: 30px;
        margin-right: auto;
    }
}