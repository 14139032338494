.noticeboard_container{
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
}
.notice_board_img{
    width: 100%;
    height: 250px;
}
.notices{
    background-color: #ffffff;
    display: inline-block;
    padding: 5px 20px 5px 20px;
    margin: 10px 10px 0px 0px;
    border-radius: 50px;
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 10px;
}
.pr-1{
    padding-right: 10px;
}